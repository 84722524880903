import type { ImageLayoutType, RatingLabel } from '@slido/api-types/polls';

export interface CorrectAnswers {
  allow: boolean;
  show: boolean;
}

export interface ImageDataModel {
  thirdPartyImage?: {
    source: ThirdPartyImgSource;
    urlLarge: string;
    urlMedium: string;
    urlSmall: string;
  };
  upload?: {
    uuid: string;
  };
  uuid?: string | undefined;
}

export enum InputType {
  MultipleChoice = 'MultipleChoice',
  OpenText = 'OpenText',
  Ranking = 'Ranking',
  Rating = 'Rating',
}

export enum MultipleChoicePollRenderAs {
  Bars = 'Bars',
  Pie = 'Pie',
}

export interface Option {
  isCorrect?: boolean;
  label: string;
  order: number;
  pollQuestionUuid?: string;
  rank?: number;
  uuid: string;
  votesAverageScore?: number;
  votesCount: number;
  votesPercentage?: number;
}

export interface Question {
  active: boolean;
  allowMultipleVotes?: boolean;
  correctAnswers?: CorrectAnswers;
  description: string | null;
  image: ImageDataModel | null;
  imageLayout: ImageLayoutType;
  inputType: InputType;
  isProfanityFilterEnabled?: boolean;
  isSurveyQuestionRequired?: boolean;
  maxChars?: number;
  maxCharsEnabled?: boolean;
  maxRating?: number;
  maxSelectableOptions?: number;
  options?: Option[];
  order: number;
  pollUuid: string;
  randomizeOptions: boolean;
  randomlyGenerated?: boolean;
  rankingPoolSize: number | null;
  ratingLabel?: RatingLabel;
  renderAs?: MultipleChoicePollRenderAs;
  results: QuestionResults;
  showAsEmojis?: boolean;
  showLeaderboardAfter?: boolean;
  showParticipantNames?: boolean;
  timer: Timer;
  title: string;
  titleFormatted: undefined;
  uuid: string;
}

export interface QuestionResults {
  avgRatingScore?: number;
  displayType: PollQuestionDisplayType;
  participantCount: number;
  votes?: Vote[];
  votesCount: number;
  wordcloudData: WordcloudDatum[];
}

export enum ThirdPartyImgSource {
  GIPHY = 'giphy',
  PEXELS = 'pexels',
}

export interface Timer {
  enabled: boolean;
  expiresAfter?: number;
  pingAfter?: number;
  votingTime?: number;
  warmUpTime?: number;
}

export interface Vote {
  count?: number;
  percentage?: number;
  rating?: number;
  text?: string;
  user?: {
    avatar?: string;
    name: string;
  };
  uuid: string;
}

export interface WordcloudDatum {
  text: string;
  votesCount: number;
  weight: number;
}

export enum PollQuestionType {
  MultipleChoice = 'MultipleChoice',
  OpenText = 'OpenText',
  QuizMultipleChoice = 'QuizMultipleChoice',
  Ranking = 'Ranking',
  Rating = 'Rating',
  WordCloud = 'WordCloud',
}

export enum PollQuestionDisplayType {
  HorizontalBars = 'HorizontalBars',
  HorizontalBarsAvg = 'HorizontalBarsAvg',
  HorizontalBarsCount = 'HorizontalBarsCount',
  VerticalBars = 'VerticalBars',
  VerticalBarsCount = 'VerticalBarsCount',
  VotesList = 'VotesList',
  Wordcloud = 'Wordcloud',
}
