import type { PollDataModel } from '@slido/api-types/polls';

import type { Question } from './poll-question.interfaces';

export interface DataModelResults {
  participantCount: number;
}

export enum LeaderboardStatus {
  Final = 'final',
  Interim = 'interim',
  Off = 'off',
}

export enum PollType {
  Quiz = 'Quiz',
  SimplePoll = 'SimplePoll',
  Survey = 'Survey',
}

/* EVENT POLLS */
export interface PollsDataModel {
  active: boolean;
  dateActive: string | null;
  dateCreated: string;
  dateUpdated: string;
  eventUuid: string;
  isQuiz: boolean;
  leaderboard?: LeaderboardStatus;
  order: number;
  presentationUuid: string | undefined;
  questions: Question[];
  results: DataModelResults;
  sectionUuid: string;
  showResults: boolean;
  speakerUuid: string | null;
  title: string | null;
  titleFormatted: undefined;
  uuid: string;
  votingLocked: boolean;
}

export function getPollType(poll: PollDataModel): PollType {
  if (poll.isQuiz) {
    return PollType.Quiz;
  } else if (poll.questions.length > 1) {
    return PollType.Survey;
  }

  return PollType.SimplePoll;
}
